import request from '@/router/axios';
import {businessUrl, pvhApiBase} from "@/config/env";
//设备编号
export const machineList = (machineModelId) => {
    return request({
        url : businessUrl + "machine/selectByMachineModelId?machineModelId=" + machineModelId,
        method: "get",
        parmas: {
            machineModelId
        }
    })
}
//品种
export const productList = () => {
    return request({
        url : businessUrl + "product/selectPage",
        method: "post",
        data: {
        }
    })
}
//工作中心
export const departmentList = () => {
    return request({
        url : businessUrl + "department/selectPage",
        method: "post",
        data: {
            type: 3
        }
    })
}
//工作中心下拉
export const departmentListSelect = () => {
    return request({
        url : businessUrl + "department/selectWorkShopName?type=3",
        method: "get",
        parmas: {
            type: 3
        }
    })
}
//设备机型
export const machineModelList = () => {
    return request({
        url : businessUrl + "machineModel/selectAll",
        method: "post",
        data: {
        }
    })
}
//产品批次
export const productBatchList = () => {
    return request({
        url : businessUrl + "productBatch/selectPage",
        method: "post",
        data: {
        }
    })
}
//工序
export const wklnList = () => {
    return request({
        url : businessUrl + "process/selectAll",
        method: "get",
        data: {
        }
    })
}
//纱线用途
export const mtrList = () => {
    return request({
        url : businessUrl + "dict/selectDictByCategoryId",
        method: "get",
        data: {
            categoryId: "Ptype"
        }
    })
}
//计量单位
export const unitList= () => {
    return request({
        url : businessUrl + "unit/selectPage",
        method: "post",
        data: {
        }
    })
}
//车间
export const calssList= () => {
    return request({
        url : businessUrl + "department/selectWorkShopName?type=1",
        method: "get",
        parmas: {
            type: 0
        }
    })
}
//班组
export const refrechList= (workShopId) => {
    return request({
        url : businessUrl + "department/selectGroupName",
        method: "post",
        params: {
            workShopId
        }
    })
}
//岗位
export const workList= () => {
    return request({
        url : businessUrl + "post/selectAllPost",
        method: "post",
        data: {
        }
    })
}
//产品
export const productWork = () => {
    return request({
        url: businessUrl + "product/select",
        method: "post",
        data: {}
    })
}
//产品品种查询批次
export const getpoccessByproduct = (id) => request({
    url: businessUrl + "productBatch/selectBatch?productId=" + id,
    method: "get"
})
//根据产品查批次
export const selectBatchByproduct = (productId) => request({
    url: businessUrl + "productBatch/selectBatch",
    method: "post",
    data: {
        productId
    }
})
//根据机型获取机器
export const getmachineByMachineModelId = (id) => request({
    url: businessUrl + "machine/selectByMachineModelId?machineModelId=" + id,
    method: "get"
})
//生产工序获取工艺项目
export const getwklnWorkBywkln = (id) => request({
    url: businessUrl + "specParam/selectByPid?pid=" + id,
    method: "get"
})
//工艺员
export const getPerson = () =>request({
    url: businessUrl + "corpUsers/selectAll",
    method: "get"
})
//排班规则
export const getClassList = () => request({
    url: businessUrl + "scheduleRules/selectAll",
    method: "post"
})
//物料分类
export const getMtrType = () => request({
    url: businessUrl + "productCategory/select",
    method: "get"
})
//品种属性
export const getProps = () => request({
    url: businessUrl + "dict/selectDictByCategoryId?categoryId=productType",
    method: "get"
})
//机台
export const getMachineListAll = (userId) => {
    return request({
        url : businessUrl + "/machine/selectAll?userId="+userId,
        method: "get",
        data: {

        }
    })
}
//岗位
export const saveUserMa= (params) => {
    return request({
        url : businessUrl + "machineUsers/updateOrSave",
        method: "post",
        data:[...params]
    })
}
//查询所有车间、工作中心的名字
export const getCJAndGZ= (type) => {
    return request({
    url : businessUrl + "department/selectWorkShopName?type="+type,
        method: "get",
        data:{}
    })
}
//班组
export const getBZ= (workShopId) => {
    let params = new FormData()
    params.append('workShopId', workShopId)
    return request({
        url : businessUrl + "/department/selectGroupName",
        method: "post",
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data:params
    })
}
export const machinelist = (params) => {
    return request({
        url : businessUrl + "/corpUsers/selectUserMachines",
        method: "post",
        data: {
            ...params
        }
    })
}
//部门
export const getworkAll = () => {
    return request({
        url : businessUrl + "department/selectWorkShopName?type=0",
        method: "get",
        parmas: {
            type: 0
        }
    })
}
//班组List
export const getBZAll = () => {
    return request({
        url: businessUrl + "department/selectWorkShopName?type=2",
        method: "get",
        parmas: {
            type: 2
        }
    })
}
//故障类型list
export const getFaultTypeAll = () => {
    return request({
        url: businessUrl + "dict/selectDictByCategoryId?categoryId=faultType",
        method: "get"
    })
}
//设备机台下拉
export const getMechinaAll = () => request({
    url: businessUrl + "machine/selectMachineAll",
    method: "get",
    parmas: {
    }
})
//机型编码
export const getMachineCodeAll = () => request({
    url: businessUrl + "machineModel/selectAll",
    method: "post"
})
//设备类型
export const getdeviceType = () => request({
    url: businessUrl + "machineModel/selectAll",
    method: "post"
})

//工段
export const getDictName = () => request({
    url: businessUrl + "dict/selectDictByCategoryId?categoryId=sectionType",
    method:"get"
})

//员工账号状态
export const status = (type) => {
    let dic = [];
    if (type == 0) {
        dic = [{
                label: '离职',
                value: 1
        }, {
                label: '在职',
                value: 0
            }];
    } else if (type == 1) {
        dic = [{
            label: "待确认",
            value: -1
        },
            {
                label: "离职",
                value: 1
            }];
    } else if (type == -1) {
        dic = [{
            label: '在职',
            value: 0
        }, {
            label: "拒绝",
            value: -9
            }, {
                label: "待确认",
                value: -1
            }];
    } else if (type == -9) {
        dic = [{
            label: "待确认",
            value: -1
        }, {
                label: "拒绝",
                value: -9
            }];
    }
    return dic;

}

//纱管颜色
export const getTubeColorId = () => request({
    url: businessUrl + "tubeColor/selectPage",
    method: "post",
    data: {
        "status":0
    }
})

//查询所有纱管颜色
export const getAllTubeColorId = () => request({
    url: businessUrl + "tubeColor/selectAll",
    method: "get",
})

//班制
export const getTimp = () => request({
    url: businessUrl + "shift/selectByCorpId",
    method:"get",
})

//班组下拉人员
export const getGroupUser = (departmentId) => request({
    url: businessUrl + "corpUsers/selectUserByDept",
    method: "post",
    data: {
        departmentId
    }
})
//根据故障类型查故障代码
export const getFaultCodeBytType = (id) => {
    return request({
        url : businessUrl + "faultInformation/selectByData",
        method: "post",
        data: {
            type : id
        }
    })
}

export const machineUsersdelete = (id) => {
    return request({
        // url: pvhApiBase + "Tracing/machineUsers/delete",
        url: businessUrl + "machineUsers/delete",
        method: "delete",
        params: {
            id
        }
    })
}
